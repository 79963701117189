@tailwind base;
@tailwind components;
@tailwind utilities;

.caret {
  transition: transform 0.2s ease;
}

.caret.rotate-180 {
  transform: rotate(180deg);
}

.montserrat-100 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
.montserrat-200 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.montserrat-300 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.montserrat-400 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.montserrat-500 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.montserrat-600 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.montserrat-700 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.montserrat-800 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.montserrat-900 > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

/* body {
  background-color: #111827;
} */

/* Table Styling */
table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
}

/* Header Styling */
table thead {
  background-color: #1f2937; /* Dark gray (tailwind bg-gray-800) */
  color: #9ca3af; /* Lighter gray (tailwind text-gray-400) */
}

/* Row Styling */
table tbody tr {
  background-color: #111827; /* Darker gray (tailwind bg-gray-900) */
  transition: background-color 0.2s ease;
}

/* Cell Borders */
table tbody td {
  border-top: 1px solid #374151; /* Gray border (tailwind border-gray-800) */
}

/* Accent Colors */
table tbody td.text-green-400 {
  color: #10b981; /* Green (tailwind text-green-400) */
}

table tbody td.text-yellow-400 {
  color: #fbbf24; /* Yellow (tailwind text-yellow-400) */
}

table tbody td.text-red-400 {
  color: #f87171; /* Red (tailwind text-red-400) */
}

table tbody td.text-gray-400 {
  color: #9ca3af; /* Default gray for unavailable data */
}
html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
